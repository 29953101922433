((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * NAVIGATION
	 * 
	 * Accessibility based on WAI-ARIA Authoring Practices 1.1:
	 * https://www.w3.org/TR/wai-aria-practices-1.1/#menu
	 * 
	 * Note: navigation.js does not modify objects in the DOM by adding
	 * classes or other markup other than the following three things:
	 * 
	 * 	1. Setting aria-expanded on buttons which open submenus to 
	 * 	   either true or false to show that the menu is open or 
	 * 	   closed, respectively.
	 * 
	 * 	2. Changing the tabindex to 0 if an anchor tag or button is
	 * 	   focused, or -1 if not (i.e. - "roving tab index").
	 * 
	 *  3. When using the priority plus method of hiding menu items that don't
	 * 	   fit within their container, and showing a button to toggle those
	 *     items, the following happens:
	 * 
	 * 	   a. The style 'visibility: hidden' and class '.navigation__item--invisible'
	 * 		  are added to nav items that don't fit in the container.
	 *  
	 * 	   b. .navigation__item--last-visible is added to the last of the
	 * 		  visible nav items.
	 * 
	 * 	   Note: Setting toggle button to display: none will stop menu items
	 * 	   from being hidden. In order to hide the toggle button until it's
	 * 	   needed, use visibility: hidden instead.
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
	
	// JQuery DOM objects
	const $navigationItems = $('.navigation');
	const $navItemsSubMenuToggles = $('.navigation__item-link').filter('[aria-haspopup="true"]');
	const $navItemsWithSubMenus = $('.navigation__item').filter(function() {
		return $(this).find('.navigation__item-link').filter('[aria-haspopup="true"]').length > 0;
	});
	const $menuToggles = $('.navigation__toggle');
	const $navItemsLinks = $('.navigation__item-link');
	const $navItemsSubLinks = $('.navigation__sub-nav a');
	const elementsThatReceiveFocus = 'a, button, input, select, textarea, [tabindex="0"]';

	// Conditional variables (true/false)
	const isTouch = Modernizr.touchevents;

	function isScreenXS() {
		return window.innerWidth < 768;
	}

	function isMenuOpen($menuToggle) {
		return $menuToggle.attr('aria-expanded') === 'true';
	}

	function isMegamenuOpen($navigation) {
		const $menuToggle = $navigation.find('.navigation__toggle');

		return isMenuOpen($menuToggle)
	}

	function isHidden($navItem) {
		return $navItem.css('display') === 'none' || $navItem.css('visibility') === 'hidden';
	}

	function isToggleNavigableWithLinks($navLinks) {
		const $firstNavLink = $($navLinks[0]);
		const isTopLevelLinks = $firstNavLink.hasClass('navigation__item-link');
		const $navigation = $firstNavLink.closest('.navigation');
		const $menuToggle = $navigation.find('.navigation__toggle');

		return (isTopLevelLinks &&
				!isScreenXS() &&
				!isMegamenuOpen($navigation) &&
				!isHidden($menuToggle));
	}

	function isParentMenuLinkVisible($childLink) {
		return !isHidden($childLink.closest('.navigation__item').find('.navigation__item-link'));
	}

	function setOrientationOfNavList($navList) {
		if (isScreenXS()) {
			$navList.attr('aria-orientation', 'vertical');
		} else if ($navList.width() > $navList.height()) {
			$navList.attr('aria-orientation', 'horizontal');
		} else {
			$navList.attr('aria-orientation', 'vertical');
		}
	}
 
	function openMenu($menuToggle) {
		$menuToggle.attr('aria-expanded', 'true');
		return $menuToggle;
	}

	function closeMenu($menuToggle) {
		$menuToggle.attr('aria-expanded', 'false');
		return $menuToggle;
	}

	function openMegamenu($navigation) {
		const $menuToggle = $navigation.find('.navigation__toggle');
		const $navList = $navigation.find('.navigation__list');

		openMenu($menuToggle);
		setOrientationOfNavList($navList);
	}

	function closeMegamenu($navigation) {
		const $menuToggle = $navigation.find('.navigation__toggle');
		const $navList = $navigation.find('.navigation__list');

		closeMenu($menuToggle);
		closeAllSubMenus($navigation);
		setOrientationOfNavList($navList);
	}

	function openSubMenu($menuToggle) {
		if ($menuToggle.attr('aria-haspopup') === 'true') openMenu($menuToggle);
	}

	function closeSubMenu($menuToggle) {
		const $navItemsSubLinks = $menuToggle.parent().find('.navigation__sub-nav-item-link, .navigation_sub-nav-label-header a');
		
		unsetTabIndex($navItemsSubLinks);

		if ($menuToggle.attr('aria-haspopup') === 'true') closeMenu($menuToggle);
	}

	// Use this function to automatically open submenus when they're focused by 
	// the keyboard, similar to a hover state.
	function autoOpenSubMenu($menuToggle) {
		// if (!isScreenXS()) openSubMenu($menuToggle);
	}

	function closeAllSubMenus($navigation) {
		const $navItemsLinks = $navigation.find('.navigation__item-link');
		const $navItemsSubLinks = $navigation.find('.navigation__sub-nav-item-link, .navigation_sub-nav-label-header a');
		const $navItemsSubMenuToggles = $navItemsLinks.filter('[aria-haspopup="true"]');

		closeMenu($navItemsSubMenuToggles);
		unsetTabIndex($navItemsLinks);
		unsetTabIndex($navItemsSubLinks);
	}

	function setTabIndex($menuItem) {
		$menuItem.attr('tabindex', '0');
		return $menuItem;
	}

	function unsetTabIndex($menuItem) {
		$menuItem.attr('tabindex', '-1');
		return $menuItem;
	}

	function filterVisibleNavLinks($navLinks) {
		return $navLinks.filter(function() {
			return !isHidden($(this));
		});
	}

	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	/**-----------------------------------------------------------------
	 * 
	 * 	WINDOW RESIZE EVENTS
	 * 
	 -----------------------------------------------------------------*/
	
	// iOS triggers resize event on scroll, so we need to check to be sure the 
	// window actually resized vs the original size.
	let originalWindowWidth = window.innerWidth;

	// Hide any nav items that overflow the container
	const hideOverflowNavItems = debounce(function() {
		$navigationItems.each(function(index, navigation) {
			const $navigation = $(navigation);
			const $navItemsLinks = $navigation.find('.navigation__item-link');

			// No need to hide items if the navigation isn't visible
			if (isHidden($navigation.find('.navigation__list'))) return;

			const $menuToggle = $navigation.find('.navigation__toggle');
			const $navItems = $navigation.find('.navigation__item');

			// Reset toggle and all nav items to not be hidden
			$menuToggle.css({ 'visibility': 'visible', 'width': '', 'padding': ''});
			$menuToggle.addClass('navigation__toggle-open');

			if ($menuToggle.data('no-priority-plus') === 'true') {
				$menuToggle.css('display', '');
			}

			$navItems.each((index, navItem) => {
				const $navItem = $(navItem);
				const $navItemLink = $navItem.find('.navigation__item-link');
				$navItem.css('visibility', '');
				$navItem.removeClass('navigation__item--last-visible');
				$navItem.removeClass('navigation__item--invisible');
				$navItemLink.css({ 'width': '', 'padding': '' });
			});

			// Only hide nav items on SM+
			if (!isScreenXS()) {
				const navWidth = $navigation.width();
				let navItemsWidth = $menuToggle.outerWidth();
				let foundLastVisible = false;

				if ($menuToggle.css('display') === 'none') {
					// Don't hide overflow items if the menu toggle is set to display: none. Instead, hide
					// all items when they don't all fit, and show the toggle.
					$menuToggle.data('no-priority-plus', 'true');

					const navListWidth = $navigation.find('.navigation__list').width();

					if (navListWidth > navWidth) {
						$navItems.each((index, navItem) => {
							const $navItem = $(navItem);
							const $navItemLink = $navItem.find('.navigation__item-link');

							$navItem.css('visibility', 'hidden');
							$navItem.addClass('navigation__item--invisible');
							$navItemLink.css({ 'width': '0', 'padding': '0' });
						});

						$menuToggle.css('display', 'block');
						$menuToggle.css('visibility', 'visible');
						$menuToggle.addClass('navigation__toggle-open');
						$menuToggle.css({ 'width': '', 'padding': '' });

						return;
					} else {
						return;
					}
				} else {
					$navItems.each((index, navItem) => {
						const $navItem = $(navItem);
						navItemsWidth += $navItem.outerWidth();
	
						if (navItemsWidth > navWidth) {
							const $navItemLink = $navItem.find('.navigation__item-link');
							
							if (!foundLastVisible && index > 0) {
								const $prevNavItem = $($navItems[index - 1]);
								$prevNavItem.addClass('navigation__item--last-visible');
								foundLastVisible = true;
							}
	
							$navItem.css('visibility', 'hidden');
							$navItem.addClass('navigation__item--invisible');
							$navItemLink.css({ 'width': '0', 'padding': '0' });
							
							if (index === $navItems.length - 1) {
								$menuToggle.css('visibility', 'visible');
								$menuToggle.addClass('navigation__toggle-open');
								$menuToggle.css({ 'width': '', 'padding': '' });
							}
						} else if (index === $navItems.length - 1) {
							// Hide the toggle button if all the nav items fit within the container
							$menuToggle.css('visibility', 'hidden');
							$menuToggle.removeClass('navigation__toggle-open');
							$menuToggle.css({ 'width': '0', 'padding': '0' });
	
							if ($menuToggle.attr('tabindex') === 0) {
								unsetTabIndex($menuToggle);
								setTabIndex($(filterVisibleNavLinks($navItems)[0]));
							}
						}
					});
				}
			}
		});
	}, 1);

	// setTimeout gives the initial CSS time to load before hiding the overflow items
	setTimeout(hideOverflowNavItems, 200);

	// Move the tabindex focus from the nav items to the toggle button if on mobile
	if (isScreenXS()) {
		$navigationItems.each(function(index, navigation) {
			const $navigation = $(navigation);
			const $navList = $navigation.find('.navigation__list');

			unsetTabIndex($navigation.find('.navigation__item-link'));
			setTabIndex($navigation.find('.navigation__toggle'));
			setOrientationOfNavList($navList);
		});
	}

	// Update where the tabindex is focused when user switches between mobile and desktop views
	$(window).resize(function() {
		// iOS triggers resize event on scroll, so we need to check to 
		// be sure the window actually resized.
		const currentWindowWidth = window.innerWidth;

		// The window resized
		if (currentWindowWidth != originalWindowWidth) {
			originalWindowWidth = currentWindowWidth;

			$navigationItems.each(function(index, navigation) {
				const $navigation = $(navigation);
				const $menuToggle = $navigation.find('.navigation__toggle');
				const $navItemLinks = $navigation.find('.navigation__item-link');

				if(isScreenXS()) {
					const $navList = $navigation.find('.navigation__list');

					$menuToggle.css('visibility', '');
					unsetTabIndex($navItemLinks);
					setTabIndex($menuToggle);
					setOrientationOfNavList($navList);
				} else {
					closeMegamenu($navigation);
					hideOverflowNavItems();

					const $navItemLinksVisible = filterVisibleNavLinks($navItemLinks);
					
					if ($navItemLinksVisible.length > 0) {
						unsetTabIndex($menuToggle);
						setTabIndex($navItemLinksVisible.first());
					}
				}
			});
		}
	});

	/**-----------------------------------------------------------------
	 * 
	 * 	MOUSE & TOUCH EVENTS
	 * 
	 -----------------------------------------------------------------*/
	
	function closeMenuOnClickOutside(event) {
		const $clickTarget = $(event.target);
		const $clickedNavigation = $clickTarget.closest('.navigation');
		const clickedNavigationName = $clickedNavigation.attr('aria-label');

		$navigationItems.each(function(index, navigation) {
			const $navigation = $(navigation);

			if (!($navigation.attr('aria-label') === clickedNavigationName)) {
				if (isMegamenuOpen($navigation)) {
					const $menuToggle = $navigation.find('.navigation__toggle');
			
					if (!isHidden($menuToggle)) {
						// setTabIndex($menuToggle);

						// If user didn't click on an element that can receive focus, then put the focus 
						// on the toggle of the menu that was just closed.
						// if (!$clickTarget.is(elementsThatReceiveFocus) && 
						// 	$clickTarget.closest(elementsThatReceiveFocus).length === 0) {
							
						// 	$menuToggle.focus();
						// }
						// The above is commented out, because if the user is clicking with the mouse 
						// then focus doens't always have to be shown. Focus is mainly for keyboard users.
					}

					closeMegamenu($navigation);
				} else {
					const $openSubMenuToggle = $navigation.find('.navigation__item-link[aria-expanded="true"]');

					// Is a submenu open even though the megamenu isn't? Ex: when the sub menu is visible on LG
					if ($openSubMenuToggle.length > 0) {
						closeSubMenu($openSubMenuToggle);
						// setTabIndex($openSubMenuToggle)
						
						// If user didn't click on an element that can receive focus, then put the focus 
						// on the toggle of the menu that was just closed.
						// if (!$clickTarget.is(elementsThatReceiveFocus) && 
						// 	$clickTarget.closest(elementsThatReceiveFocus).length === 0) {
							
						// 	$openSubMenuToggle.focus();
						// }
					}
				}
			}
		});
	}

	function closeMenuOnClickLink(event) {
		const $clickTarget = $(event.target);
		const $navigation = $clickTarget.closest('.navigation');
		// const $menuToggle = $navigation.find('.navigation__toggle');

		if($clickTarget.is('a')) {
			if (isMegamenuOpen($navigation)) {
				setTimeout(function() {
					closeMegamenu($navigation);
				}, 1000);
				// if (!isHidden($menuToggle)) setTabIndex($menuToggle);
			} else {
				const $openSubMenuToggle = $navigation.find('.navigation__item-link[aria-expanded="true"]');

				// Is a submenu open even though the megamenu isn't? Ex: when the sub menu is visible on LG
				if ($openSubMenuToggle.length > 0) {
					setTimeout(function() {
						closeSubMenu($openSubMenuToggle);
						// setTabIndex($openSubMenuToggle);
					}, 1000);
				}
			}
		}
	}

	// Toggle the menu when user clicks the toggle button
	$menuToggles.on('click', function(event) {
		const $clickTarget = $(event.target);
		const $navigation = $clickTarget.closest('.navigation');
		
		if(isMegamenuOpen($navigation)) {
			closeMegamenu($navigation);
		} else {
			openMegamenu($navigation);
		}

		event.preventDefault();
	});

	// Toggle the sub menu when user clicks the sub menu toggle button
	$navItemsSubMenuToggles.on('click', function(event) {
		const $menuToggle = $(this);
		// const $navigation = $menuToggle.closest('.navigation');

		if(isMenuOpen($menuToggle)) {
			closeSubMenu($menuToggle);
			// setTabIndex($menuToggle).focus();
		} else {
			// Close all other open submenus
			const $allMenuToggles = $menuToggle.closest('.navigation__list').find('.navigation__item-link');
			
			$allMenuToggles.each(function() {
				closeSubMenu($(this));
			});

			// Now open the clicked submenu
			openSubMenu($menuToggle);
		}

		event.preventDefault();
	});
	
	// Close menu if user clicked/touched outside the menu or on a link
	if (isTouch) {
		// iOS scrolling fires touchstart --> touchmove --> touchend,
		// and we need to be sure we're not registering a scroll as a click.
		let documentClick;

		$(document).on('touchstart', function() {
			documentClick = true;
		});

		$(document).on('touchmove', function() {
			documentClick = false;
		});

		$(document).on('click touchend', function(event) {
			if (event.type == "click") documentClick = true;
			if (documentClick){
				closeMenuOnClickOutside(event);
				closeMenuOnClickLink(event);
			}
		});
	} else {
		$(document).on('click', function (event) {
			closeMenuOnClickOutside(event);
			closeMenuOnClickLink(event);
		});
	}

	// If the page includes the settings bar with buttons to increase and decrease the 
	// size of the text, then perform a calculation about whether or not to hide any of the
	// nav items after the text has been resized.
	$('.resetTextSize, .increaseTextSize, .decreaseTextSize').on('click', function() {
		hideOverflowNavItems();
	});

	/**-----------------------------------------------------------------
	 * 
	 * 	KEYBOARD EVENTS
	 * 
	 -----------------------------------------------------------------*/

	// Keycodes
	const tabKey = 9;
	const enterKey = 13;
	const spaceKey = 32;
	const escKey = 27;
	const downArrowKey = 40;
	const upArrowKey = 38;
	const leftArrowKey = 37;
	const rightArrowKey = 39;
	const homeKey = 36;
	const endKey = 35;

	function moveFocusToFirstItem($navLinks) {
		const $firstNavItemLink = $($navLinks[0]);

		// Move to the first navItem
		setTabIndex($firstNavItemLink).focus();

		// Open navItem's submenu if it has one
		autoOpenSubMenu($firstNavItemLink);
	}

	function moveFocusToLastItem($navLinks) {
		const $menuToggle = $navLinks.closest('.navigation').find('.navigation__toggle');

		if (isToggleNavigableWithLinks($navLinks)) {
			// Move to the toggle button if it's visible
			setTabIndex($menuToggle).focus();
		} else {
			const $lastNavItemLink = $($navLinks[$navLinks.length - 1]);

			// Move to the last navItem
			setTabIndex($lastNavItemLink).focus();

			// Open navItem's submenu if it has one
			autoOpenSubMenu($lastNavItemLink);
		}
	}

	function moveFocusToNextItem($navLinks, event) {
		const $menuToggle = $navLinks.closest('.navigation').find('.navigation__toggle');
		const $visibleNavLinks = filterVisibleNavLinks($navLinks);

		$visibleNavLinks.each(function(index, navItemLink) {

			if (navItemLink === event.target) {
				const $navItemLink = $(navItemLink);
				unsetTabIndex($navItemLink);
				
				// Close navItem's submenu if it has one
				if (!isScreenXS()) closeSubMenu($navItemLink);

				// If it's the last navItem then...
				if (index === $visibleNavLinks.length - 1) {

					if (isToggleNavigableWithLinks($navLinks)) {
						// Move to the toggle button if it's visible
						setTabIndex($menuToggle).focus();
					} else {
						moveFocusToFirstItem($visibleNavLinks);
					}
				} else {
					const $nextNavItemLink = $($visibleNavLinks[index + 1]);

					// Move tabindex to the next navItem
					setTabIndex($nextNavItemLink).focus();

					// Open navItem's submenu if it has one
					autoOpenSubMenu($nextNavItemLink);
				}
			}
		});

		// Prevents scrolling when down arrow key is pressed
		event.preventDefault();
	}

	function moveFocusToPreviousItem($navLinks, event) {
		const $visibleNavLinks = filterVisibleNavLinks($navLinks);

		$visibleNavLinks.each(function(index, navItemLink) {

			if (navItemLink === event.target) {
				const $navItemLink = $(navItemLink);
				unsetTabIndex($navItemLink);

				// Close navItem's submenu if it has one
				if (!isScreenXS()) closeSubMenu($navItemLink);

				// If it's the first navItem then...
				if (index === 0) {
					moveFocusToLastItem($visibleNavLinks);
				} else {
					const $prevNavItemLink = $($visibleNavLinks[index - 1]);
					// Move tabindex to the previous navItem
					setTabIndex($prevNavItemLink).focus();

					// Open navItem's submenu if it has one
					autoOpenSubMenu($prevNavItemLink);
				}
			}
		});

		// Prevents scrolling when down arrow key is pressed
		event.preventDefault();
	}

	function moveFocusToNextItemThatMatchesCharacter($navLinks, event) {
		const $navLinksArray = filterVisibleNavLinks($navLinks).slice(0); // Clone the array
		const $menuToggle = $navLinks.closest('.navigation').find('.navigation__toggle');

		if (isToggleNavigableWithLinks($navLinks)) $navLinksArray.push($menuToggle[0]);
		
		$navLinksArray.each(function(index, navItemLink) {
			if (navItemLink === event.target) { // Find the currently focused item
				const $navItemLink = $(navItemLink);

				for (let i = 1; i < $navLinksArray.length + 1; i++) {
					const indexToSearch = (index + i) % $navLinksArray.length;
					const $thisNavItem = $($navLinksArray[indexToSearch]);
					const firstLetter = $thisNavItem.text().replace(/[^0-9a-z]/gi, '').charCodeAt(0);
					const pressedKey = event.which;

					if (pressedKey === firstLetter) {
						unsetTabIndex($navItemLink);
						closeSubMenu($navItemLink);
						setTabIndex($thisNavItem).focus();
						if (!isToggleNavigableWithLinks($navLinks)) {
							
							autoOpenSubMenu($thisNavItem);
						}
						break;
					}
				}
			}
		});
	}

	// MEGAMENU TOGGLE BUTTON
	$menuToggles.on('keydown', function (event) {
		const $menuToggle = $(this);
		const $navigation = $menuToggle.closest('.navigation');
		const pressedKey = event.which;
		
		// Find all item-links that either are visible or will be once the menu is opened.
		let $navItemsLinksVisible = $navigation.find('.navigation__item-link').filter(function() {
			return $(this).css('display') !== 'none';
		});

		// If there still aren't any item-links to navigate to then navigate to any 
		// sub-nav-item-links that aren't going to be hidden.
		if ($navItemsLinksVisible.length === 0) {
			$navItemsLinksVisible = $navigation.find('.navigation__sub-nav-item-link, .navigation_sub-nav-label-header a').filter(function() {
				return $(this).css('display') !== 'none';
			});
		}

		// Open the menu when focus is on toggleButton & user presses enter, space, or down/up key
		if (pressedKey === enterKey || 
			pressedKey === spaceKey || 
			pressedKey === downArrowKey || 
			pressedKey === upArrowKey) {
			openMegamenu($navigation);

			if (pressedKey === upArrowKey) {
				const $lastNavItemLink = $navItemsLinksVisible.last();
				
				unsetTabIndex($menuToggle);

				// 100ms delay allows animation to happen before switching focus
				setTimeout(function() {
					setTabIndex($lastNavItemLink).focus();
				}, 100);

				// Open navItem's submenu if it has one
				if (!isScreenXS()) openSubMenu($lastNavItemLink);
			} else {
				const $firstNavItemLink = $navItemsLinksVisible.first();

				unsetTabIndex($menuToggle);

				// 100ms delay allows animation to happen before switching focus
				setTimeout(function() {
					setTabIndex($firstNavItemLink).focus();
				}, 100);

				// Open navItem's submenu if it has one
				if (!isScreenXS()) openSubMenu($firstNavItemLink);
			}
			
			// Prevents scrolling when space key is pressed
			event.preventDefault();
		}

		if (!isScreenXS()) {
			if (pressedKey === rightArrowKey ||
				pressedKey === homeKey) {
				// Find nav links that aren't also set to visibility: hidden due to priority plus
				const $navItemsVisible = filterVisibleNavLinks($navItemsLinksVisible);

				// Only move to a nav item link if one is visible
				if ($navItemsVisible.length > 0) {
					// Move focus to first navItem
					unsetTabIndex($menuToggle);
					setTabIndex($($navItemsVisible[0])).focus();
				}
				
				event.preventDefault();
			}

			if (pressedKey === endKey) {
				event.preventDefault();
			}

			if (pressedKey === leftArrowKey) {
				// Find nav links that aren't also set to visibility: hidden due to priority plus
				const $navItemsVisible = filterVisibleNavLinks($navItemsLinksVisible);

				// Only move to a nav item link if one is visible
				if ($navItemsVisible.length > 0) {
					// Move focus to last navItem
					unsetTabIndex($menuToggle);
					setTabIndex($($navItemsVisible[$navItemsVisible.length - 1])).focus();
				}
			}
		}

		if (isToggleNavigableWithLinks($navItemsLinksVisible)) {
			// Moves focus to the next item having a name that starts with the typed character
			if ((pressedKey >= 48 && pressedKey <= 57) || 	// 0 - 9
				(pressedKey >= 65 && pressedKey <= 90)) {		// a - z
				
				moveFocusToNextItemThatMatchesCharacter($navItemsLinksVisible, event);
			}
		}
	});

	// TOP LEVEL LINKS
	$navItemsLinks.on('keydown', function(event) {
		const $thisNavItemLink = $(this);
		const $navigation = $thisNavItemLink.closest('.navigation');
		const $menuToggle = $navigation.find('.navigation__toggle');
		const $navItemsLinks = $navigation.find('.navigation__item-link')
		const $navItemsLinksVisible = filterVisibleNavLinks($navItemsLinks);
		const pressedKey = event.which;
		
		if (pressedKey === escKey ||
			pressedKey === tabKey) {
			if (isMegamenuOpen($navigation)) {
				closeMegamenu($navigation);
				unsetTabIndex($thisNavItemLink);
				setTabIndex($menuToggle).focus();
			}
		}

		if (pressedKey === homeKey) {
			unsetTabIndex($thisNavItemLink);
			closeSubMenu($thisNavItemLink);
			moveFocusToFirstItem($navItemsLinksVisible);
			event.preventDefault();
		}

		if (pressedKey === endKey) {
			unsetTabIndex($thisNavItemLink);
			closeSubMenu($thisNavItemLink);
			moveFocusToLastItem($navItemsLinksVisible);
			event.preventDefault();
		}

		// Moves focus to the next item having a name that starts with the typed character
		if ((pressedKey >= 48 && pressedKey <= 57) || 	// 0 - 9
			(pressedKey >= 65 && pressedKey <= 90)) {		// a - z
			
			moveFocusToNextItemThatMatchesCharacter($navItemsLinksVisible, event);
		}
		
		// Move tabindex down the list
		if ((isScreenXS() && pressedKey === downArrowKey) ||
			(!isScreenXS() && isMegamenuOpen($navigation) && pressedKey === downArrowKey) ||
			(!isScreenXS() && !isMegamenuOpen($navigation) && pressedKey === rightArrowKey)) {
			
			moveFocusToNextItem($navItemsLinksVisible, event);

		} else if (pressedKey === enterKey || pressedKey === spaceKey ||
				   (isScreenXS() && pressedKey === rightArrowKey) ||
				   (!isScreenXS() && isMegamenuOpen($navigation) && pressedKey === rightArrowKey) ||
				   (!isScreenXS() && !isMegamenuOpen($navigation) && pressedKey === downArrowKey)) {

			// Move tab index down onto first child link in sub nav
			if ($thisNavItemLink.attr('aria-haspopup') === 'true') {
				unsetTabIndex(openMenu($thisNavItemLink));
				const $firstChildLink = $(filterVisibleNavLinks($thisNavItemLink.parent().find('.navigation__sub-nav a'))[0]);
				setTabIndex($firstChildLink).focus();
			}

			// Prevents scrolling when space or down arrow key is pressed
			event.preventDefault();

		} else if (pressedKey === upArrowKey || pressedKey === downArrowKey) {
			event.preventDefault();
		}

		// Move tabindex up the list
		if ((isScreenXS() && pressedKey === upArrowKey) ||
			(!isScreenXS() && isMegamenuOpen($navigation) && pressedKey === upArrowKey) ||
			(!isScreenXS() && !isMegamenuOpen($navigation) && pressedKey === leftArrowKey)) {

			moveFocusToPreviousItem($navItemsLinksVisible, event);

		} else if ((isScreenXS() && pressedKey === leftArrowKey) ||
				   (!isScreenXS() && isMegamenuOpen($navigation) && pressedKey === leftArrowKey) ||
				   (!isScreenXS() && !isMegamenuOpen($navigation) && pressedKey === upArrowKey)) {

			// Move tab index down onto last child link in sub nav
			if ($thisNavItemLink.attr('aria-haspopup') === 'true') {
				unsetTabIndex(openMenu($thisNavItemLink));
				const subNavLinks = filterVisibleNavLinks($thisNavItemLink.parent().find('.navigation__sub-nav a'));
				const $firstChildLink = $(subNavLinks[subNavLinks.length - 1]);
				setTabIndex($firstChildLink).focus();

				// Prevents scrolling when down arrow key is pressed
				event.preventDefault();
			}
		} else if (pressedKey === upArrowKey || pressedKey === downArrowKey) {
			event.preventDefault();
		}
	});

	// SUB MENU LINKS
	$navItemsSubLinks.on('keydown', function(event) {
		const $thisNavItemLink = $(this);
		const $navigation = $thisNavItemLink.closest('.navigation');
		const $menuToggle = $navigation.find('.navigation__toggle');
		const pressedKey = event.which;
		let $navSubLinks;

		if (isParentMenuLinkVisible($thisNavItemLink)) {
			$navSubLinks = filterVisibleNavLinks($thisNavItemLink.closest('.navigation__sub-nav').find('a'));
		} else {
			// Find all other sub-nav-item-links in the navigation
			$navSubLinks = filterVisibleNavLinks($navigation.find('.navigation__sub-nav-item-link, .navigation_sub-nav-label-header a'));
		}
		
		if (pressedKey === escKey) {
			let $subNavToggle = $thisNavItemLink.closest('.navigation__item').find('.navigation__item-link');
			
			if (!isHidden($subNavToggle)) {
				closeSubMenu($subNavToggle);
				unsetTabIndex($thisNavItemLink);
				setTabIndex($subNavToggle).focus();
				autoOpenSubMenu($subNavToggle);
			} else {
				unsetTabIndex($thisNavItemLink);
				setTabIndex($menuToggle).focus();
				closeMegamenu($navigation);
			}
		}

		if (pressedKey === tabKey) {
			unsetTabIndex($thisNavItemLink);

			if (isMegamenuOpen($navigation)) {
				setTabIndex($menuToggle);
				closeMegamenu($navigation);
			} else {
				const $parentNavItemLink = $thisNavItemLink.closest('.navigation__item').find('.navigation__item-link');
				setTabIndex($parentNavItemLink);
				closeSubMenu($parentNavItemLink);
			}
		}

		if (pressedKey === homeKey) {
			unsetTabIndex($thisNavItemLink);
			moveFocusToFirstItem($navSubLinks);
			event.preventDefault();
		}

		if (pressedKey === endKey) {
			unsetTabIndex($thisNavItemLink);
			moveFocusToLastItem($navSubLinks);
			event.preventDefault();
		}

		// Moves focus to the next item having a name that starts with the typed character
		if ((pressedKey >= 48 && pressedKey <= 57) || 	// 0 - 9
			(pressedKey >= 65 && pressedKey <= 90)) {		// a - z
			
			moveFocusToNextItemThatMatchesCharacter($navSubLinks, event);
		}
		
		// Move tabindex down the list
		if (pressedKey === downArrowKey ||
			pressedKey === rightArrowKey) {
			moveFocusToNextItem($navSubLinks, event);
		}

		// Move tabindex up the list
		if (pressedKey === upArrowKey ||
			pressedKey === leftArrowKey) {
			moveFocusToPreviousItem($navSubLinks, event);
		}

		if (pressedKey === spaceKey) event.preventDefault();
	});
})(jQuery));
