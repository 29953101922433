((($) => {
	'use strict';
	/**
	 * social-share module implementation.
	 *
	 * @author Mark Aplet <markaplet@symsoftsolutions.com>
	 */

	var docTitle = encodeURIComponent(sanitize(document.title));
	var docURL = window.location.href;
	docURL = encodeURIComponent(sanitize(docURL));

	// TODO: flickr (only for sharing photos), instagram(only for sharing photos. not currently allowed by api),
	// pinterest(uses a link that goes on an <a> wrapping an image), vimeo & youtube(only for sharing videos)

	$("a.social-share-facebook").on('click', function(e) {
		PopupCentered('https://www.facebook.com/sharer/sharer.php?u=' + docURL + '&display=popup','socialshare','658','450');
		e.preventDefault();
	});

	$("a.social-share-twitter").on('click', function(e) {
		PopupCentered('https://twitter.com/intent/tweet?text=' + docTitle + '&url=' + docURL,'socialshare','568','531');
		e.preventDefault();
	});

	$("a.social-share-google-plus").on('click', function(e) {
		PopupCentered('https://plus.google.com/share?url=' + docURL,'socialshare','550','552');
		e.preventDefault();
	});

	$("a.social-share-linkedin").on('click', function(e) {
		PopupCentered('https://www.linkedin.com/shareArticle?mini=true&url=' + docURL,'socialshare','520','570');
		e.preventDefault();
	});

	$("a.social-share-email").attr('href',  "mailto:?subject=" + docTitle + "&body=%0a" + docURL + "%0a%0a");

	function sanitize(sText) {
		// Remove html tags from a string
		var re = /<\S[^>]*>/g;
		sText = sText.replace(re, " ");
		return sText;
	}


	// Display a popup window centered over the parent window. Works in all browsers
	// except Opera with multiple displays. Opera displays the popup on the primary
	// display only.
	function PopupCentered(url, popupName, popupWidth, popupHeight) {
		// screenX/Y for IE9+ FF Op Chrome, screenLeft/Top for IE7 IE8 Chrome, left/top for old FF
		var parentLeft = window.screenX ? window.screenX : window.screenLeft ? window.screenLeft : screen.left ? screen.left : 0;
		var parentTop = window.screenY ? window.screenY : window.screenTop ? window.screenTop : screen.top ? screen.top : 0;

		var parentWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		var parentHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		var popupLeft = (parentWidth / 2) - (popupWidth / 2) + parentLeft;
		var popupTop = (parentHeight / 2) - (popupHeight / 2) + parentTop;

		window.open(url, popupName, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', left=' + popupLeft + ', top=' + popupTop);
	}
})(jQuery));
