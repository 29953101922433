((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * LOCAL NAV
     * 
	 * For this project LOCAL NAV uses scrollmagic to pin the local nav
     * once it scrolls to the top of the page.
	 * 
	 * Note: local-nav.js does not modify objects in the DOM by adding
	 * classes or other markup other than the following three things:
	 * 
	 * 	1. Setting aria-expanded on toggle button to 
	 * 	   either true or false to show that the menu is open or 
	 * 	   closed, respectively.
     * 
     *  2. Adding the class 'local-nav--expanded' to local-nav when
     *     it is expanded. This is sometimes needed for styling.
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
    
    /**-----------------------------------------------------------------
     * 
     * Setup Bootstrap Tooltip
     * 
     -----------------------------------------------------------------*/

     $('[data-toggle="tooltip"]').tooltip()

    /**-----------------------------------------------------------------
     * 
     * Setup scrollmagic
     * 
     -----------------------------------------------------------------*/

    // init scrollmagic
    // var controller = new ScrollMagic.Controller();

    // const pageHeight = document.height;
    // const localNavHeight = $('.local-nav > ul').outerHeight() + 96;
    // const duration = pageHeight + localNavHeight;

    // // create a scene
    // new ScrollMagic.Scene({
    //     duration: duration,    // the scene should last for a scroll distance of 1000px
    //     offset: 138        // start this scene after scrolling for 150px
    // })
    // .setPin(".local-nav") // pins the element for the the scene's duration
    // .addTo(controller); // assign the scene to the controller


    /**-----------------------------------------------------------------
     * 
     * Setup the logic to toggle collapsable local nav
     * 
     -----------------------------------------------------------------*/

    const $localNavToggle = $('#local-nav__toggle');

    // We can stop here if the local navigation doesn't have a toggle button
    if ($localNavToggle.length > 0) {
        const $localNav = $('.local-nav');
        const isTouch = Modernizr.touchevents;

        function isMenuOpen($menuToggle) {
            return $menuToggle.attr('aria-expanded') === 'true';
        }
 
        function openMenu($menuToggle) {
            $menuToggle.attr('aria-expanded', 'true');
            $localNav.addClass('local-nav--expanded');
            // $localNav.attr('data-switching-panels', 'false');
            return $menuToggle;
        }
    
        function closeMenu($menuToggle) {
            // switchToActivePanel();
            $menuToggle.attr('aria-expanded', 'false');
            $localNav.removeClass('local-nav--expanded');
            return $menuToggle;
        }

        // function switchToActivePanel() {
        //     const $currentPage = $localNav.find('.active');
        //     let $currentPageCollapsable;

        //     // First close any open menus
        //     $localNav.find('.children.collapse.in').each(function() {
        //         const $collapsable = $(this);

        //         if ($collapsable.find('.active').length === 0) {
        //             $collapsable.collapse('hide');
        //             // $localNav.attr('data-switching-panels', 'true');
        //         }
        //     })

        //     if ($currentPage.hasClass('parent')) {
        //         $currentPageCollapsable = $currentPage.find('.children');
        //     }
            
        //     if ($currentPage.hasClass('child')) {
        //         $currentPageCollapsable = $currentPage.closest('.children');
        //     }

        //     setTimeout(function() {
        //         $currentPageCollapsable.collapse('show');
        //     }, 500);
        // }
    
        /**-----------------------------------------------------------------
         * 
         * 	MOUSE & TOUCH EVENTS
         * 
         -----------------------------------------------------------------*/
        
        function closeMenuOnClickOutside(event) {
            const $clickTarget = $(event.target);
            const wasLocalNavClicked = $clickTarget.closest('.local-nav').length > 0;
    
            if (!wasLocalNavClicked) {
                if(isMenuOpen($localNavToggle)) {
                    closeMenu($localNavToggle);
                }
            }
        }
    
        // Toggle the menu when user clicks the toggle button
        $localNavToggle.on('click', function(event) {          
            if(isMenuOpen($localNavToggle)) {
                closeMenu($localNavToggle);
            } else {
                openMenu($localNavToggle);
            }
    
            // event.preventDefault();
        });
        
        // Close menu if user clicked/touched outside the menu
        if (isTouch) {
            // iOS scrolling fires touchstart --> touchmove --> touchend,
            // and we need to be sure we're not registering a scroll as a click.
            let documentClick;
    
            $(document).on('touchstart', function() {
                documentClick = true;
            });
    
            $(document).on('touchmove', function() {
                documentClick = false;
            });
    
            $(document).on('click touchend', function(event) {
                if (event.type == "click") documentClick = true;
                if (documentClick){
                    closeMenuOnClickOutside(event);
                }
            });
        } else {
            $(document).on('click', function (event) {
                closeMenuOnClickOutside(event);
            });
        }
    
        /**-----------------------------------------------------------------
         * 
         * 	KEYBOARD EVENTS
         * 
         -----------------------------------------------------------------*/
    
        // Keycodes
        const tabKey = 9;
        const enterKey = 13;
        const spaceKey = 32;
        const escKey = 27;
    
        // MEGAMENU TOGGLE BUTTON
        $localNavToggle.on('keydown', function (event) {
            const pressedKey = event.which;
    
            // Open the menu when focus is on toggleButton & user presses enter, space, or down/up key
            if (pressedKey === enterKey ||
                pressedKey === spaceKey) {

                if(isMenuOpen($localNavToggle)) {
                    closeMenu($localNavToggle);
                } else {
                    openMenu($localNavToggle);
                }
                  
                // Prevents scrolling when space key is pressed
                event.preventDefault();
            }
        });
    }
})(jQuery));
