((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * Radio Group
	 * 
	 * Accessibility based on WAI-ARIA Authoring Practices 1.1:
	 * https://www.w3.org/TR/wai-aria-practices-1.1/#radiobutton
	 * 
	 * Note: radio-group.js does not modify objects in the DOM by adding
	 * classes or other markup other than the following three things:
	 * 
	 * 	1. Setting aria-checked to true or false depending upon whether
	 * 	   or not the radio button is checked or not.
	 * 
	 * 	2. Changing the tabindex to 0 if a tabable item is
	 * 	   focused, or -1 if not (i.e. - "roving tab index").
	 * 
	 * 	3. Setting the first radio to tabindex 0 if no radios are checked.
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
	
	// JQuery DOM objects
	const $radioGroups = $('.radio-group');
	const $radioButtons = $('.radio-group [role="radio"]');

	function isRadioChecked($radio) {
		return $radio.attr('aria-checked') === 'true';
	}

	function selectRadio($radio) {
		$radio.attr('aria-checked', 'true');
		setTabIndex($radio).focus();
	}

	function unselectRadio($radio) {
		$radio.attr('aria-checked', 'false');
		unsetTabIndex($radio);
	}

	function setTabIndex($radio) {
		$radio.attr('tabindex', '0');
		return $radio;
	}

	function unsetTabIndex($radio) {
		$radio.attr('tabindex', '-1');
		return $radio;
	}

	// Setup tabindex if no radio buttons are checked.
	$radioGroups.each(function(index, radioGroup) {
		const $radioItems = $(radioGroup).find('[role="radio"]');
		let isAnyRadioChecked = false;

		$radioItems.each(function(index, radio) {
			if (isRadioChecked($(radio))) isAnyRadioChecked = true;
		});

		// If none are checked then set tabindex of first radio to 0.
		if (!isAnyRadioChecked) {
			setTabIndex($($radioItems[0]));
		}
	});
	

	/**-----------------------------------------------------------------
	 * 
	 * 	MOUSE & TOUCH EVENTS
	 * 
	 -----------------------------------------------------------------*/

	// Toggle the menu when user clicks the toggle button
	$radioButtons.on('click', function(event) {
		const $thisRadio = $(this);
		const $radioItems = $thisRadio.parent().find('[role="radio"]');

		if (!isRadioChecked($thisRadio)) {
			selectRadio($thisRadio);

			// Unselect all other radio buttons
			$radioItems.each(function(index, radio) {
				const $radio = $(radio);

				if (!$radio.is($thisRadio)) {
					unselectRadio($radio);
				}
			});
		}

		event.preventDefault();
	});

	/**-----------------------------------------------------------------
	 * 
	 * 	MOUSE & KEYBOARD EVENTS
	 * 
	 -----------------------------------------------------------------*/

	// Keycodes
	const downArrowKey = 40;
	const upArrowKey = 38;
	const leftArrowKey = 37;
	const rightArrowKey = 39;
	const spaceKey = 32;

	function moveFocusToFirstItem($radioItems) {
		const $firstRadio = $($radioItems[0]);

		// Move to the first radioItem
		selectRadio($firstRadio);
	}

	function moveFocusToLastItem($radioItems) {
		const $lastRadio = $($radioItems[$radioItems.length - 1]);

		// Move to the last radioItem
		selectRadio($lastRadio);
	}

	function moveFocusToNextItem($radioItems, event) {
		$radioItems.each(function(index, radio) {

			if (radio === event.target) {
				const $radio = $(radio);
				unselectRadio($radio);

				// If it's the last radioItem then...
				if (index === $radioItems.length - 1) {
					moveFocusToFirstItem($radioItems);
				} else {
					const $nextRadio = $($radioItems[index + 1]);

					// Move tabindex to the next radioItem
					selectRadio($nextRadio);
				}
			}
		});

		// Prevents scrolling when down arrow key is pressed
		event.preventDefault();
	}

	function moveFocusToPreviousItem($radioItems, event) {
		$radioItems.each(function(index, radio) {

			if (radio === event.target) {
				const $radio = $(radio);
				unselectRadio($radio);

				// If it's the first navItem then...
				if (index === 0) {
					moveFocusToLastItem($radioItems);
				} else {
					const $prevRadio = $($radioItems[index - 1]);
					// Move tabindex to the previous navItem
					selectRadio($prevRadio);
				}
			}
		});

		// Prevents scrolling when down arrow key is pressed
		event.preventDefault();
	}

	// Radio Buttons
	$radioButtons.on('keydown', function(event) {
		const $thisRadio = $(this);
		const $radioItems = $thisRadio.parent().find('[role="radio"]');
		const pressedKey = event.which;
		
		// Move tabindex down the list
		if (pressedKey === rightArrowKey ||
			pressedKey === downArrowKey) {
			moveFocusToNextItem($radioItems, event);
		}

		// Move tabindex up the list
		if (pressedKey === leftArrowKey ||
			pressedKey === upArrowKey) {
			moveFocusToPreviousItem($radioItems, event);
		}

		if (pressedKey === spaceKey) {
			if (!isRadioChecked($thisRadio)) selectRadio($thisRadio);
			event.preventDefault();
		}
	});
})(jQuery));
