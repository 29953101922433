((($) => {
	'use strict';
	/**
	 * 	img-srcset module implementation.
	 *
	 * 	Looks for a div with class .img-srcset--cropped and sets the background image 
	 * 	to the src of the img it is wrapping, whether that is the default src or srcset 
	 * 	chosen by the browser.
	 */

	class ResponsiveBackgroundImage {
		constructor(element) {
			this.element = element;
			this.img = element.querySelector('img');
			this.src = '';
	
			if (this.img) {
				this.img.addEventListener('load', () => {
					this.update();
				});
		
				if (this.img.complete) {
					this.update();
				}
			}
		}
	
		update() {
			let src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;
			if (this.src !== src) {
				this.src = src;
				this.element.style.backgroundImage = 'url("' + this.src + '")';
			}
		}
	}

	let elements = document.querySelectorAll('.img-srcset--cropped');

	for (let i=0; i<elements.length; i++) {  
		new ResponsiveBackgroundImage(elements[i]);
	}

})(jQuery));
