/* ----------------------------------------------------------------------------------
	localnav2dropdown
	
	Creates a duplicate of the Local Nav component as a Bootstrap dropdown,
	and places it in the container defined by data-container at the position
	defined by data-position ('top' or 'bottom'). Ex:

	<nav class="local-nav local-nav--responsive" data-container="main-content" data-position="bottom">
			
---------------------------------------------------------------------------------- */
((($) => {
	'use strict';

	const $localNav = $('.local-nav--responsive');
	const isCollapsable = $localNav.hasClass('local-nav--collapsable');
	const localNavTitle = $localNav.find('.active > span').length > 0 ? $localNav.find('.active > span').html() : $localNav.find('.local-nav-header').length > 0 ? $localNav.find('.local-nav-header').html() : $('h1').html();
	const $localNavBody = $($localNav.find('> ul')).clone();
	const $responsiveContainer = $(`${$localNav.data('container')}`);
	const responsivePosition = $localNav.data('position');

	if ($responsiveContainer) {
		let dropdownClasses = "local-nav-dropdown dropdown";

		if (isCollapsable) {
			dropdownClasses = "local-nav-dropdown local-nav-dropdown--collapsable dropdown"
		}

		const $localNavDropdown = $('<div>', {
			'class': dropdownClasses
		});
	
		const dropdownButton = `<button id="local-nav-dropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">${localNavTitle}<span class="local-nav-dropdown__toggle"></span></button>`
		$localNavBody.addClass('dropdown-menu').attr('aria-labelledby', 'local-nav-dropdown');

		
		// Because we are making a clone, we cannot have duplicate IDs, and we need to have
		// unique attributes to be referenced by the Bootstrap collapse function.
		if ($localNavBody.attr('id')) {
			$localNavBody.attr('id', `${$localNavBody.attr('id')}-dropdown`);
		}

		$localNavBody.removeAttr('aria-labelledby');

		$localNavBody.find('ul, a, span, button').each(function() {
			const $this = $(this);
			
			if ($this.attr('id')) {
				$this.attr('id', `${$this.attr('id')}-dropdown`);
			}

			if ($this.attr('aria-controls')) {
				$this.attr('aria-controls', `${$this.attr('aria-controls')}-dropdown`);
			}
			
			if ($this.attr('aria-labelledby')) {
				$this.attr('aria-labelledby', `${$this.attr('aria-labelledby')}-dropdown`);
			}
			
			if ($this.data('parent')) {
				$this.attr('data-parent', `${$this.data('parent')}-dropdown`);
			}
			
			if ($this.data('target')) {
				$this.attr('data-target', `${$this.data('target')}-dropdown`);
			}
		});
		// End cleaning up attributes
	
		$localNavDropdown.append(dropdownButton, $localNavBody);

		if (responsivePosition === 'bottom') {
			$responsiveContainer.append($localNavDropdown);
		} else {
			$responsiveContainer.prepend($localNavDropdown);
		}



		$(document).on('click', '.local-nav-dropdown .dropdown-menu', function (e) {
            const $clickTarget = $(event.target);

            e.stopPropagation();

            if ($clickTarget.hasClass('local-nav__child-links-toggle') || $clickTarget.closest('.local-nav__child-links-toggle').length > 0) {
                const $toggle = $(this);
                
                if ($toggle.attr('aria-expanded') === 'true') {
                    $toggle.closest('.local-nav__parent').find('.local-nav__children').collapse('hide');
                } else {
                    $toggle.closest('.local-nav__parent').find('.local-nav__children').collapse('show');                   
                }                
            }
        });
	}
})(jQuery));
