((($) => {
	'use strict';
	/**
	 * Hero module implementation.
     * 
     * Slick-Carousel Init
	 *
	 */

    // Don't change these default Slick Carousel settings. Instead set the settings you
    // would like to be different when you initialize the carousel below.
    var defaultCarouselSettings = {
        accessibility: true,
        adaptiveHeight: false,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: true,
        asNavFor: null,
        // appendArrows: $(element),
        // appendDots: $(element),
        // prevArrow: $('<button type="button" class="slick-prev">Previous</button>'),
        // nextArrow: $('<button type="button" class="slick-next">Next</button>'),
        centerMode: false,
        centerPadding: '50px',
        cssEase: 'ease',
        // customPaging: 
        dots: false,
        dotsClass: 'slick-dots',
        draggable: true,
        fade: false,
        focusOnSelect: false,
        focusOnChange: true,
        easing: 'linear',
        edgeFriction: 0.15,
        infinite: true,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        // lazyLoad: 'progressive',
        mobileFirst: false,
        pauseOnFocus: true,
        pauseOnHover: true,
        pauseOnDotsHover: false,
        respondTo: 'window',
        // responsive: 
        rows: 1,
        // slide:
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        swipe: true,
        swipeToSlide: false,
        touchMove: true,
        touchThreshold: 5,
        useCSS: true,
        useTransform: true,
        variableWidth: false,
        vertical: false,
        verticalSwiping: false,
        rtl: false,
        waitForAnimate: true,
        zIndex: 1000,
    }

    var isAutoplay = false;
    
    // // If first slide is a video, but player isn't ready, then pause the carousel until video is ready.
    // var isFirstSlideVideo = $($('.slide')[0]).hasClass('video');
    // isAutoplay = true;

    // if (isFirstSlideVideo) {
    //     isAutoplay = false;
    // }

    // Initialize homepage banner using Slick (/Foundation/libs/slick/slick.js)
    var $carouselBanner = $('.carousel-banner');
    var swipe = $carouselBanner.data('swipe') || false;
    var fade = $carouselBanner.data('fade') || false;

    $carouselBanner.slick($.extend(defaultCarouselSettings, {
        autoplay: isAutoplay,
        autoplaySpeed: 3000,
        arrows: false,
        dots: true,
        dotsClass: 'slick-dots',
        draggable: swipe,
        fade: fade,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipeToSlide: swipe,
    }));

    // If next slide is a video, but player isn't ready, then pause carousel until video is ready.
    $carouselBanner.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        if (!isPlayerReady) {				
            if (slick.$slides[nextSlide].children[0].dataset && slick.$slides[nextSlide].children[0].dataset.youtubeVideoId) {
                $carouselBanner.slick('slickPause');
            }
        }
    });

})(jQuery));

/**
     *  Play/Pause YouTube videos when videos are in the carousel
     */
    var slideList = $('.slide');
    var videoList = {};

    slideList.each(function(index) {
        var $this = $(this);

        if ($this.hasClass('video') && !$this.parent().parent().hasClass('slick-cloned')) {
            var $videoContainer = $this.children('[data-youtube-video-id]');
            
            // Add the YouTube video id as the container's id so that the container 
            // can be found by the YouTube iFrame player API.
            $videoContainer.attr('id', $videoContainer.data('youtube-video-id'));
            videoList[index] = $videoContainer;
        }
    });

    if (!$.isEmptyObject(videoList)) {
        // This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        var players = {};
        var isPlayerReady = false;
        var isFirstVideoStarted = false;

        function onYouTubeIframeAPIReady() {
            $.each(videoList, function (i, video) {
                // This function creates an <iframe> (and YouTube player)
                // after the API code downloads.
                var youTubeId = video.data('youtube-video-id');

                // YouTube api looks for a container with id = youTubeId, 
                // and creates a player in its place.
                players[youTubeId] = new YT.Player(youTubeId, {
                    height: '100%',
                    width: '100%',
                    videoId: youTubeId,
                    playerVars: {
                        // controls: 0, // Hide video player controls
                        // disablekb: 1, // Disable keyboard controls
                        // fs: 0, // Hide full screen button
                        modestbranding: 1, // Hides the YouTube logo in the control bar
                        // playsinline: 1, // Prevents fullscreen playback in iOS, plays inline instead
                        rel: 0, // Hide showing related videos when playback is done
                        showinfo: 0, // Hide video info before playing the video
                    },
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange,
                    }
                });
            });
        }
        
        // The API will call this function when the video player is ready.
        function onPlayerReady(event) {
            var player = event.target;
            var youTubeId = event.target.a.attributes['data-youtube-video-id'].nodeValue;
            var $carouselBanner = $('.carousel-banner');

            isPlayerReady = true;

            // Autoplay the video
            // If the current slide is a video and it hasn't yet been started, then play the first video
            // if (!isFirstVideoStarted) {
            //     var currentSlideIndex = $carouselBanner.slick('slickCurrentSlide');
            //     var isCurrentSlideHasVideo = $($carouselBanner.slick('getSlick').$slides[currentSlideIndex]).find('[data-youtube-video-id]') !== 0;

            //     if (isCurrentSlideHasVideo) {
            //         player.playVideo();
            //         isFirstVideoStarted = true;
            //     }
            // }

            // Pause the carousel once the video starts
            $carouselBanner.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                // Stop the video if the user changes the slide
                if ($(slick.$slides[currentSlide].children[0]).children('.video').length) {
                    player.stopVideo();
                }

                // If carousel and video are autoplaying, then pause the carousel and start the video.
                // if (slick.$slides[nextSlide].children[0].dataset.youtubeVideoId === youTubeId) {
                //     $carouselBanner.slick('slickPause');
                //     players[youTubeId].playVideo();
                // }
            });
        }

        // The API calls this function when the player's state changes.
        // The function indicates that when playing a video (state=1),
        // the player should play for six seconds and then stop.
        // var done = false;
        function onPlayerStateChange(event) {
            var $carouselBanner = $('.carousel-banner');

            if (event.data == YT.PlayerState.ENDED) {
                // $carouselBanner.slick('slickPlay').slick('slickNext');
            } else if (event.data == YT.PlayerState.PLAYING) {
                $carouselBanner.slick('slickPause');
            }
        }
    }