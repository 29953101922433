((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * SITE SEARCH
	 * 
	 * Site Search as two parts:
	 * 1. Toggling the site search between visible and hidden.
	 * 2. Changing the scroll position when the search is dynamic. (see bottom)
	 * 
	 * Note: site-search.js does not modify objects in the DOM by adding
	 * classes or other markup other than the following four things:
	 * 
	 * 	1. Setting aria-expanded on the toggle depending upon whether
	 * 	   the search field is expanded or not.
	 * 
	 *  2. Setting aria-haspopup on the toggle depending upon whether
	 * 	   or not the search field is always visible or not.
	 * 
	 * 	3. Setting the toggle button to display:block if it should be
	 * 	   shown.
	 * 
	 * 	4. Changing the tabindex to 0 if an anchor tag or button is
	 * 	   focused, or -1 if not (i.e. - "roving tab index").
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
	
	$('.site-search').each(function() {
		const $this = $(this);
	 
		// JQuery DOM objects
		const $siteSearchToggle = $this.find('.site-search__toggle');
		const $siteSearchForm = $this.find('.site-search__form');
		const $siteSearchInput = $siteSearchForm.find('input');
		const $siteSearchButton = $siteSearchForm.find('button');
		const elementsThatReceiveFocus = 'a, button, input, select, textarea, [tabindex="0"]';

		// Conditional variables (true/false)
		const isTouch = Modernizr.touchevents;
		const isFormHiddenDefault = $siteSearchToggle.hasClass('site-search__toggle-form-hidden-default');

		function isScreenXS() {
			return window.innerWidth < 768;
		}

		function isMenuOpen() {
			return $siteSearchToggle.attr('aria-expanded') === 'true';
		}

		function isSiteSearchFormVisible() {
			return $siteSearchForm.is(':visible');
		}

		function doesSiteSearchFormFit() {
			const $globalHeader = $('.global-header');
			const globalHeaderWidth = $globalHeader.width();
			const globalHeaderChildren = $globalHeader.children();
			let childrenTotalWidth = 0;

			globalHeaderChildren.each(function(index, child) {
				childrenTotalWidth += $(child).outerWidth(true);
			});
			if (childrenTotalWidth < globalHeaderWidth) return true;

			return false;
		}
	
		function openMenu() {
			$siteSearchToggle.attr('aria-expanded', 'true');
			return $siteSearchToggle;
		}

		function closeMenu() {
			$siteSearchToggle.attr('aria-expanded', 'false');
			return $siteSearchToggle;
		}

		function showToggle() {
			$siteSearchToggle.attr('aria-haspopup', 'true');
			$siteSearchToggle.attr('aria-expanded', 'false');
			$siteSearchToggle.css('display', 'block');
		}
		
		function hideToggle() {
			$siteSearchToggle.removeAttr('aria-haspopup');
			$siteSearchToggle.removeAttr('aria-expanded');
			$siteSearchToggle.css('display', 'none');
		}

		function setTabIndex($menuItem) {
			$menuItem.attr('tabindex', '0');
			return $menuItem;
		}

		function unsetTabIndex($menuItem) {
			$menuItem.attr('tabindex', '-1');
			return $menuItem;
		}
		
		function clearTabIndex($menuItem) {
			$menuItem.removeAttr('tabindex');
			return $menuItem;
		}

		function setDialogRole() {
			$siteSearchForm.attr('role', 'dialog');
		}

		function unsetDialogRole() {
			$siteSearchForm.removeAttr('role');
		}

		/**-----------------------------------------------------------------
		 * 
		 * 	WINDOW RESIZE EVENTS
		 * 
		 -----------------------------------------------------------------*/
		
		// iOS triggers resize event on scroll, so we need to check to be sure the 
		// window actually resized vs the original size.
		let originalWindowWidth = window.innerWidth;

		// Move the tabindex focus from the nav items to the toggle button if on mobile
		if (isScreenXS() && !isSiteSearchFormVisible()) {
			setDialogRole();
			unsetTabIndex($siteSearchInput);
			unsetTabIndex($siteSearchButton);
			setTabIndex($siteSearchToggle);
			showToggle();
		} else if(!isSiteSearchFormVisible()) {
			setDialogRole();
			showToggle();
		} else if(!doesSiteSearchFormFit()) {
			setDialogRole();
			showToggle();
		}

		// Update where the tabindex is focused when user switches between mobile and desktop views
		$(window).resize(function() {
			// iOS triggers resize event on scroll, so we need to check to 
			// be sure the window actually resized.
			const currentWindowWidth = window.innerWidth;
			
			// The window resized
			if (currentWindowWidth != originalWindowWidth) {
				originalWindowWidth = currentWindowWidth;

				if(isScreenXS()) {
					if (!isMenuOpen()) {
						setTabIndex($siteSearchToggle);
						showToggle();
						setDialogRole();

						if (!isSiteSearchFormVisible()) {
							setTimeout(() => {
								unsetTabIndex($siteSearchInput);
								unsetTabIndex($siteSearchButton);
							}, 1000)
						}
					}
				} else {
					closeMenu();
					hideToggle();

					if (isSiteSearchFormVisible()) {
						if(!doesSiteSearchFormFit() || isFormHiddenDefault) {
							setDialogRole();
							showToggle();
						} else {
							clearTabIndex($siteSearchToggle);
							clearTabIndex($siteSearchInput);
							clearTabIndex($siteSearchButton);
							unsetDialogRole();
							hideToggle();
						}
					} else {
						showToggle();
						setDialogRole();
					}
				}
			}
		});

		/**-----------------------------------------------------------------
		 * 
		 * 	MOUSE & TOUCH EVENTS
		 * 
		 -----------------------------------------------------------------*/
		
		function closeMenuOnClickOutside(event) {
			const $clickTarget = $(event.target);
			const $isClickedOutsideSiteSearch = $clickTarget.closest($this).length === 0;

			if ($isClickedOutsideSiteSearch) {
				if (isMenuOpen()) {
					if ($siteSearchToggle.is(':visible')) setTabIndex($siteSearchToggle);

					closeMenu();
					setTabIndex($siteSearchToggle);
					unsetTabIndex($siteSearchInput);
					unsetTabIndex($siteSearchButton);

					// If user didn't click on an element that can receive focus, then put the focus 
					// on the toggle of the menu that was just closed.
					// if (!$clickTarget.is(elementsThatReceiveFocus) &&
					// 	$clickTarget.closest(elementsThatReceiveFocus).length === 0) {

					// 	$siteSearchToggle.focus();
					// }
					// The above is commented out, because if the user is clicking with the mouse 
					// then focus doens't always have to be shown. Focus is mainly for keyboard users.
				}
			}
		}

		// Toggle the menu when user clicks the toggle button
		$siteSearchToggle.on('click', function(event) {
			if(isMenuOpen()) {
				closeMenu();
				setTabIndex($siteSearchToggle).focus();
				unsetTabIndex($siteSearchInput);
				unsetTabIndex($siteSearchButton);
			} else {
				openMenu();
				setTabIndex($siteSearchInput).focus();
				unsetTabIndex($siteSearchToggle);
			}

			event.preventDefault();
		});

		// Close menu if user clicked/touched outside the menu
		if (isTouch) {
			// iOS scrolling fires touchstart --> touchmove --> touchend,
			// and we need to be sure we're not registering a scroll as a click.
			let documentClick;

			$(document).on('touchstart', function() {
				documentClick = true;
			});

			$(document).on('touchmove', function() {
				documentClick = false;
			});

			$(document).on('click touchend', function(event) {
				if (event.type == "click") documentClick = true;
				if (documentClick){
					closeMenuOnClickOutside(event);
				}
			});
		} else {
			$(document).on('click', function (event) {
				closeMenuOnClickOutside(event);
			});
		}

		/**-----------------------------------------------------------------
		 * 
		 * 	KEYBOARD EVENTS
		 * 
		 -----------------------------------------------------------------*/

		// Keycodes
		const enterKey = 13;
		const spaceKey = 32;
		const escKey = 27;
		const tabKey = 9;

		// MEGAMENU TOGGLE BUTTON
		$siteSearchToggle.on('keydown', function(event) {
			const pressedKey = event.which;

			// Open the menu when focus is on toggleButton & user presses enter or space key
			if (pressedKey === enterKey || 
				pressedKey === spaceKey) {
				openMenu();
				unsetTabIndex($siteSearchToggle);
				setTabIndex($siteSearchInput).focus();
			
				// Prevents scrolling when space key is pressed
				event.preventDefault();
			}
		});

		$siteSearchForm.on('keydown', function(event) {
			const pressedKey = event.which;

			if (isMenuOpen() && pressedKey === escKey) {
				closeMenu();
				unsetTabIndex($siteSearchInput);
				unsetTabIndex($siteSearchButton);
				setTabIndex($siteSearchToggle).focus();
			}
		});
		
		$siteSearchInput.on('keydown', function(event) {
			const pressedKey = event.which;

			if (isMenuOpen() && pressedKey === tabKey) {
				unsetTabIndex($siteSearchInput);
				setTabIndex($siteSearchButton).focus();
				event.preventDefault();
			}
		});
		
		$siteSearchButton.on('keydown', function(event) {
			const pressedKey = event.which;

			if (isMenuOpen() && pressedKey === tabKey) {
				unsetTabIndex($siteSearchButton);
				setTabIndex($siteSearchInput).focus();
				event.preventDefault();
			}
		});


		/**
		 * If dynamic search is setup (ie - the search results are updated on the page without reloading the page),
		 * then clicking on the search button, or pressing enter while in the input should scroll the page
		 * to the search results.
		 */

		const $dynamicSiteSearch = $this.data('is-dynamic-search') === true ? $this : $();
		
		if ($dynamicSiteSearch.length > 0) {
			const $searchBarInput = $dynamicSiteSearch.find('input');
			const $searchBarButton = $dynamicSiteSearch.find('button');
			const $searchResults = $('#search-results');
			const $searchResultsPosition = $searchResults.length > 0 ? $searchResults.offset().top - 15 : 0;

			$searchBarInput.on('keydown', function() {
				// If enter key is pressed
				if (event.which === 13) {
					window.scrollTo(0, $searchResultsPosition);
					// Focus is set to the first item of the new page of search results by searchresults.js in Sitecore
				}
			});

			$searchBarButton.on('click', function() {
				window.scrollTo(0, $searchResultsPosition);
				// Focus is set to the first item of the new page of search results by searchresults.js in Sitecore
			});
		}
	});
})(jQuery));
