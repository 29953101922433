((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * PAGINATION
	 * 
	 * This adds functionality that scrolls the page to the first search
	 * result and gives it focus when a pagination link is clicked on a
	 * dynamic search page.
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
	
	const $siteSearchComponents= $('.site-search');
	
	const $dynamicSiteSearch = $siteSearchComponents.filter(function( ) {
		return $(this).data('is-dynamic-search') === true
	});

	if ($dynamicSiteSearch.length > 0) {
		const $paginationLinks = $(".pagination a");
		const $searchResults = $('#search-results');
		const $searchResultsPosition = $searchResults.length > 0 ? $searchResults.offset().top - 15 : 0;

		function handleEvent(e) {
			$searchResults.focus();
			window.scrollTo(0, $searchResultsPosition);
			e.preventDefault();
		}

		$paginationLinks.on('keydown', function (e) {
			if (event.which === 13) {
				handleEvent(e);
			}
        });
		
		$paginationLinks.on('click', function (e) {
			handleEvent(e);
        });
	}
})(jQuery));
