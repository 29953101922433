((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * UTILITY HEADER
	 * 
	 * Note: utility-header.js does not modify objects in the DOM by adding
	 * classes or other markup other than the following two things:
	 * 
	 *  1. Setting aria-expanded on any drawer, and any button that has a 
	 * 	   drawer, depending upon whether the drawer is expanded or not.
	 * 
	 * 	2. Changing the tabindex to 0 if a tabable item is
	 * 	   focused, or -1 if not (i.e. - "roving tab index"). This feature
	 * 	   currently disabled (ie - commented out).
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
	
	// JQuery DOM objects
	const $utilityHeader = $('.utility-header');
	const $menuItems = $utilityHeader
		.find('.navigation__toggle, .utility-header__drawer-toggle, .site-search__toggle, .utility-header__link, .branding a');
	const $menuItemsWithDrawers = $utilityHeader.find('.utility-header__drawer-toggle');
	const $drawers = $('.utility-header__drawer');
	const elementsThatReceiveFocus = 'a, button, input, select, textarea, [tabindex="0"]';
	const $allDrawersTabContents = $drawers.find(elementsThatReceiveFocus);

	// Conditional variables (true/false)
	const isTouch = Modernizr.touchevents;

	function isScreenXS() {
		return window.innerWidth;
	}

	function isSubMenuOpen($toggle) {
		return $toggle.attr('aria-expanded') === 'true';
	}

	function doesItHaveADrawer($menuItem) {
		return $menuItem.hasClass('utility-header__drawer-toggle');
	}

	function openSubMenu($toggle) {
		const drawerId = $toggle.attr('aria-controls');
		const $drawer = $utilityHeader.find(`#${drawerId}`);

		$toggle.attr('aria-expanded', 'true');
		$drawer.attr('aria-expanded', 'true');

		// unsetTabIndex($toggle);
		setTabIndexToDrawersFirstTabContent($toggle);
	}

	function closeSubMenu($toggle) {
		const drawerId = $toggle.attr('aria-controls');
		const $drawer = $utilityHeader.find(`#${drawerId}`);

		$toggle.attr('aria-expanded', 'false');
		$drawer.attr('aria-expanded', 'false');

		unsetTabIndexToDrawersFirstTabContent($toggle);
		// setTabIndex($toggle);
	}

	function closeOpenSubMenus($toggle) {
		$menuItemsWithDrawers.each(function(index, toggle) {
			const $toggle = $(toggle);

			if (isSubMenuOpen($toggle)) closeSubMenu($toggle);
		});

		// Put the focus back on whichever toggle was sent as a parameter
		$toggle.focus();
	}

	function setTabIndex($menuItem) {
		$menuItem.attr('tabindex', '0');
		return $menuItem;
	}

	function unsetTabIndex($menuItem) {
		$menuItem.attr('tabindex', '-1');
		return $menuItem;
	}

	function findDrawerToggle($drawer) {
		return $utilityHeader.find(`[aria-controls=${$drawer.attr('id')}]`);
	}

	function findDrawerTabContents($drawer) {
		return $drawer.find('a, button, input, select, textarea, [tabindex="0"]');
	}

	function setTabIndexToDrawersFirstTabContent($toggle) {
		const drawerId = $toggle.attr('aria-controls');
		const $drawer = $utilityHeader.find(`#${drawerId}`);

		const $drawerTabContents = findDrawerTabContents($drawer);

		if ($drawerTabContents.length > 0) setTabIndex($($drawerTabContents[0])).focus();
	}

	function unsetTabIndexToDrawersFirstTabContent($toggle) {
		const drawerId = $toggle.attr('aria-controls');
		const $drawer = $utilityHeader.find(`#${drawerId}`);

		const $drawerTabContents = findDrawerTabContents($drawer);

		if ($drawerTabContents.length > 0) unsetTabIndex($drawerTabContents);
	}

	// Set tabindex of all menuItems - first item is 0, and the rest are -1 for roving tab index.
	// $menuItems.each(function(index, menuItem) {
	// 	const $menuItem = $(menuItem);

	// 	if (index === 0) {
	// 		setTabIndex($menuItem);
	// 	} else {
	// 		unsetTabIndex($menuItem);
	// 	}
	// });

	// Set tabindex of all drawer elements to -1 for roving tab index.
	// unsetTabIndex($allDrawersTabContents);

	/**-----------------------------------------------------------------
	 * 
	 * 	MOUSE & TOUCH EVENTS
	 * 
	 -----------------------------------------------------------------*/
	
	function closeMenuOnClickOutside(event) {
		const $clickTarget = $(event.target);
		const isClickedOutsideUtilityHeader = $clickTarget.closest('.utility-header__drawer').length === 0;
		const isClickedOutsideUtilityHeaderButton = $clickTarget.closest('.utility-header__drawer-toggle[aria-expanded="true"]').length === 0;

		if (isClickedOutsideUtilityHeader && isClickedOutsideUtilityHeaderButton) {
			const $openMenuToggle = $menuItemsWithDrawers.filter('[aria-expanded="true"]');

			// Is there a menu toggle that is expanded
			if ($openMenuToggle.length > 0) {
				closeSubMenu($openMenuToggle);
				
				// If user didn't click on an element that can receive focus, then put the focus 
				// on the toggle of the menu that was just closed.
				if (!$clickTarget.is(elementsThatReceiveFocus) && 
					$clickTarget.closest(elementsThatReceiveFocus).length === 0) {
					
					$openMenuToggle.focus();
				}
			}
		}
	}

	// Toggle the menu when user clicks the toggle button
	$menuItemsWithDrawers.on('click', function(event) {
		const $toggle = $(this);

		if(!isSubMenuOpen($toggle)) {
			closeOpenSubMenus($toggle);
			openSubMenu($toggle);
		} else {
			closeSubMenu($toggle);
			$toggle.focus();
		}

		event.preventDefault();
	});

	// Toggle tabindex when user clicks on a menu item
	// $menuItems.on('click', function(event) {
	// 	$menuItems.each(function(index, menuItem) {
	// 		if (menuItem !== event.target) {
	// 			unsetTabIndex($(menuItem));
	// 		}
	// 	});
	// });

	// Close menu if user clicked/touched outside the menu
	if (isTouch) {
		// iOS scrolling fires touchstart --> touchmove --> touchend,
		// and we need to be sure we're not registering a scroll as a click.
		let documentClick;

		$(document).on('touchstart', function() {
			documentClick = true;
		});

		$(document).on('touchmove', function() {
			documentClick = false;
		});

		$(document).on('click touchend', function(event) {
			if (event.type == "click") documentClick = true;
			if (documentClick){
				closeMenuOnClickOutside(event);
			}
		});
	} else {
		$(document).on('click', function (event) {
			closeMenuOnClickOutside(event);
		});
	}

	/**-----------------------------------------------------------------
	 * 
	 * 	KEYBOARD EVENTS
	 * 
	 -----------------------------------------------------------------*/

	// Keycodes
	const enterKey = 13;
	const spaceKey = 32;
	const escKey = 27;
	const tabKey = 9;
	const downArrowKey = 40;
	const upArrowKey = 38;
	const leftArrowKey = 37;
	const rightArrowKey = 39;
	const homeKey = 36;
	const endKey = 35;

	function moveFocusToFirstItem($tabItems) {
		const $firstTabItem = $($tabItems[0]);

		// Move to the first navItem
		setTabIndex($firstTabItem).focus();
	}

	function moveFocusToLastItem($tabItems) {
		const $lastTabItem = $($tabItems[$tabItems.length - 1]);

		// Move to the last navItem
		setTabIndex($lastTabItem).focus();
	}

	function moveFocusToNextItem($tabItems, event) {
		$tabItems.each(function(index, tabItem) {

			if (tabItem === event.target) {
				const $tabItem = $(tabItem);
				unsetTabIndex($tabItem);

				// If it's the last navItem then...
				if (index === $tabItems.length - 1) {
					moveFocusToFirstItem($tabItems);
				} else {
					const $nextTabItem = $($tabItems[index + 1]);

					// Move tabindex to the next navItem
					setTabIndex($nextTabItem).focus();
				}
			}
		});

		// Prevents scrolling when down arrow key is pressed
		event.preventDefault();
	}

	function moveFocusToPreviousItem($tabItems, event) {
		$tabItems.each(function(index, tabItem) {

			if (tabItem === event.target) {
				const $tabItem = $(tabItem);
				unsetTabIndex($tabItem);

				// If it's the first navItem then...
				if (index === 0) {
					moveFocusToLastItem($tabItems);
				} else {
					const $prevTabItem = $($tabItems[index - 1]);
					// Move tabindex to the previous navItem
					setTabIndex($prevTabItem).focus();
				}
			}
		});

		// Prevents scrolling when down arrow key is pressed
		event.preventDefault();
	}

	function moveFocusToNextItemThatMatchesCharacter($tabItems, event) {
		$tabItems.each(function(index, tabItem) {
			if (tabItem === event.target) { // Find the currently focused item
				const $tabItem = $(tabItem);

				for (let i = 1; i < $tabItems.length + 1; i++) {
					const indexToSearch = (index + i) % $tabItems.length;
					const $thisTabItem = $($tabItems[indexToSearch]);
					const firstLetter = $thisTabItem.text().replace(/[^0-9a-z]/gi, '').charCodeAt(0);
					const pressedKey = event.which;

					if (pressedKey === firstLetter) {
						unsetTabIndex($tabItem);
						setTabIndex($thisTabItem).focus();
						break;
					}
				}
			}
		});
	}

	// Utility Header Menu Items
	$menuItems.on('keydown', function(event) {
		const $thisMenuItem = $(this);
		const pressedKey = event.which;

		// Move between menu items
		// if(!isSubMenuOpen($thisMenuItem)) {
		// 	if (pressedKey === homeKey) {
		// 		unsetTabIndex($thisMenuItem);
		// 		moveFocusToFirstItem($menuItems);
		// 		event.preventDefault();
		// 	}
	
		// 	if (pressedKey === endKey) {
		// 		unsetTabIndex($thisMenuItem);
		// 		moveFocusToLastItem($menuItems);
		// 		event.preventDefault();
		// 	}
	
		// 	// Moves focus to the next item having a name that starts with the typed character
		// 	if ((pressedKey >= 48 && pressedKey <= 57) || 	// 0 - 9
		// 		(pressedKey >= 65 && pressedKey <= 90)) {		// a - z
				
		// 		moveFocusToNextItemThatMatchesCharacter($menuItems, event);
		// 	}
			
		// 	// Move tabindex down the list
		// 	if (pressedKey === rightArrowKey) {
		// 		moveFocusToNextItem($menuItems, event);
		// 	}
	
		// 	// Move tabindex up the list
		// 	if (pressedKey === leftArrowKey) {
		// 		moveFocusToPreviousItem($menuItems, event);
		// 	}

		// 	if (pressedKey === downArrowKey ||
		// 		pressedKey === upArrowKey) event.preventDefault();
		// }

		// Open and close menu items with drawers
		if (doesItHaveADrawer($thisMenuItem)) {
			const $toggle = $thisMenuItem;

			if (pressedKey === spaceKey ||
				pressedKey === enterKey) {

				if (!isSubMenuOpen($thisMenuItem)) {
					closeOpenSubMenus($toggle);
					openSubMenu($toggle);
				} else {
					closeSubMenu($toggle);
				}

				event.preventDefault()
			}

			if (pressedKey === escKey && isSubMenuOpen($thisMenuItem)) {
				closeSubMenu($toggle);
				$toggle.focus();
			}
			
			if (pressedKey === tabKey && isSubMenuOpen($thisMenuItem)) {
				event.preventDefault();
			}
		}
	});

	// Drawers
	$drawers.on('keydown', function(event) {
		const $thisDrawer = $(this);
		const $thisToggle = findDrawerToggle($thisDrawer);
		const pressedKey = event.which;
		
		if (pressedKey === escKey) {
			closeSubMenu($thisToggle);
			$thisToggle.focus();
		}

		if (pressedKey === tabKey) {
			event.preventDefault();
		}
	});
	
	// Drawers' tabable content
	$allDrawersTabContents.on('keydown', function(event) {
		const $thisContent = $(this);
		const $thisDrawer = $thisContent.closest('.utility-header__drawer');
		const $thisDrawerTabContent = findDrawerTabContents($thisDrawer);
		const pressedKey = event.which;
		
		if (pressedKey === tabKey) {
			if (event.shiftKey) {
				unsetTabIndex($thisContent);
				moveFocusToPreviousItem($thisDrawerTabContent, event)
				event.preventDefault();
			} else {
				unsetTabIndex($thisContent);
				moveFocusToNextItem($thisDrawerTabContent, event)
				event.preventDefault();
			}
		}
	});
})(jQuery));
