((($) => {
	'use strict';
	/**-----------------------------------------------------------------
	 * NAVIGATION - Global
	 * 
	 * Sets height of global navigation to the page height.
	 * 
	 * Author <Muir Adams - SymSoft Solutions>
	 -----------------------------------------------------------------*/
	
	// JQuery DOM objects
    const $globalNavigationList = $('.navigation--global .navigation__list');

    // iOS triggers resize event on scroll, so we need to check to be sure the 
	// window actually resized vs the original size.
    let originalPageWidth = window.innerWidth;
	
	function isScreenXS() {
		return window.innerWidth < 768;
    }

    function setNavigationHeight() {
        const utilityHeight = $('.utility-header').outerHeight();
        const navHeight = $(document).height() - utilityHeight;
        $globalNavigationList.css('height', navHeight);
    }

    if (!isScreenXS()) {
        setNavigationHeight();
    }

    // Update the height of the navigation if the window resizes
	$(window).resize(function() {
		// iOS triggers resize event on scroll, so we need to check to 
		// be sure the window actually resized.
        const currentPageWidth = window.innerWidth;

		// The window resized
		if (currentPageWidth != originalPageWidth) {
            // First reset the navigation height in case it's longer than the document
            $globalNavigationList.css('height', '');

            if (!isScreenXS()) {
                originalPageWidth = currentPageWidth;
                setNavigationHeight();
            }
		}
    });
    
    // Prepend global navigation with a div that acts as an overlay
    $globalNavigationList.parent().append('<div class="navigation__overlay"></div>');

})(jQuery));
